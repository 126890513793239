import { Grid, Typography } from '@material-ui/core'
import React from 'react'

import useStyles from '../MobileStyles'
import VerticalAccordian from '../../Components/VerticalAccordian'
// import stock1 from '../../images/CoffeeStockImage1.jpeg'
import stock1 from '../../images/OfficialPhotos/OfficialPhoto11.png'

const MobilePricingPage = () => {
    const classes = useStyles();
    return (
        <div >
            <Grid container spacing={4} style={{width: '100%', padding: 0, margin: 0, marginTop: 20}}>
                <Grid item xs={12}> 
                    <Typography variant='h4' sx={{fontFamily: 'garamond'}} className={classes.PageHeader}>
                        Pricing and Packages
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', width: '100%', overflow: 'hidden', padding: 0, margin: 0, marginTop: 20}}>
                    <img className={classes.logoImage} src={stock1} style={{objectFit: 'cover', width: '100%'}} alt="Coffee"/>
                </Grid>
                <Grid item xs={12}>
                    <VerticalAccordian/>
                </Grid>
            </Grid>
        </div>
    )
}

export default MobilePricingPage