import { Typography, Grid } from '@material-ui/core'
import React from 'react'
import VerticalAccordion from '../Components/VerticalAccordian';
import useStyles from './pagesStyles';

// import stock1 from '../images/OfficialPhotos/OfficialPhoto4.png'
import stock1 from '../images/OfficialPhotos/OfficialPhoto11.png'

const PricingPage = () => {
    const bodyVariant='h6'
    const classes = useStyles();

    return (
        <div className={classes.PricingPage}> 
            <Grid container spacing={4} >
                <Grid item xs={6} lg={7} className={classes.TextBody}>
                    <VerticalAccordion/>
                </Grid>
                <Grid item xs={6} lg={5}>
                    <img className={classes.Image} src={stock1} alt="Coffee"/>
                </Grid> 
            </Grid> 
        </div>
    )
}

export default PricingPage