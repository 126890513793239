import { makeStyles } from '@material-ui/core/styles'

// hook called useStyles
const useStyles = makeStyles((theme) => ({ // the theme object is part of MUI
    LandingPage: {
        background: 'white', // cream color: #F9E4BC
        minHeight: '100vh',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
        display: 'flex'    
    },
    ImageAndTextContainer: {
        minHeight: '80vh'
    },
    Image: {
        maxWidth: "100%",
        height: 'auto',
        maxHeight: '80vh',
    },
    TextBody: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    QuoteCarousel: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    ContactPage: {
        padding: 40
    },
    FormSubmitButton: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none', // Remove box shadow on hover
        },
        backgroundColor: '#F9E4BC',
        color: 'black',
    },
    FormSubmitButtonContainer: {
        justifyContent: 'right',
        display: 'flex',
        marginTop: 40
    },
    PricingPage: {
        padding: 40,
    },
    MissionPageText: {
        justifyContent: 'center', 
        display: 'flex', 
        flexDirection: 'column'
    }

}));

export default useStyles;