import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, Typography, ListItemText } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useStyles from './componentStyles';



const VerticalAccordion = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const handleAccordionChange = (panel) => (_, isExpanded) => {
    setExpandedSection(isExpanded ? panel : null);
  };

  const classes = useStyles();

  const [headerVariant, setHeaderVariant] = useState('h5');
  const [bodyVariant, setBodyVariant] = useState(12)

  const handleResize = () => {
    let newHeaderVariant = 'h5'
    let newBodyVariant = 12
    if (window.innerWidth > 1200) {
        newHeaderVariant = 'h2'
        newBodyVariant = 20
    } else if (window.innerWidth > 800) {
        newHeaderVariant = 'h4'
        newBodyVariant = 16
    }
        
    setHeaderVariant(newHeaderVariant);
    setBodyVariant(newBodyVariant)
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div >
      <Accordion expanded={expandedSection === 'contractCatering'} onChange={handleAccordionChange('contractCatering')} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="contractCatering-content" id="contractCatering-header" className={`${classes.accordionSummary} ${expandedSection === 'contractCatering' ? classes.boldHeader : ''}`}>
          <Typography className={classes.accordianHeaders} variant={headerVariant} >Contract Catering</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <List style={{ fontSize: bodyVariant, paddingLeft: 20}}>
                <ListItem style={{fontWeight: 'bold', paddingLeft: 0}}>
                    Event hosts pay all costs for espresso bar. Event attendees drink free!
                </ListItem>
                <ListItem>
                    {'\u2022'} Includes two hours of service
                </ListItem>
                <ListItem>
                    {'\u2022'} Custom menu with full espresso bar with up to three specialty latte flavors
                </ListItem>
                <ListItem>
                    {'\u2022'} Non dairy milk option: oat milk included
                </ListItem>
                <ListItem>
                    {'\u2022'} Non Coffee items included: tea
                </ListItem>
                <ListItem>
                    {'\u2022'} Decaf Upon Request
                </ListItem>
            </List>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expandedSection === 'popUpCatering'} onChange={handleAccordionChange('popUpCatering')} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="popUpCatering-content" id="popUpCatering-header" className={`${classes.accordionSummary} ${expandedSection === 'popUpCatering' ? classes.boldHeader : ''}`}>
          <Typography className={classes.accordianHeaders} variant={headerVariant}>Pop up Catering</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <List style={{fontSize: bodyVariant, paddingLeft: 20}}>
                <ListItem style={{fontWeight: 'bold', paddingLeft: 0}}>
                    Event guests pay for their own drinks. If the minimum is not met, the event 
                    host is responsible to cover the difference
                </ListItem>
                <ListItem>
                    {'\u2022'} Includes two hours of service
                </ListItem>
                <ListItem>
                    {'\u2022'} Custom menu with full espresso bar with up to three specialty latte flavors
                </ListItem>
                <ListItem>
                    {'\u2022'} Non dairy milk option: oat milk included
                </ListItem>
                <ListItem>
                    {'\u2022'} Non Coffee items included: chai, matcha, and tea
                </ListItem>
                <ListItem>
                    {'\u2022'} Decaf Upon Request
                </ListItem>
            </List>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expandedSection === 'addOns'} onChange={handleAccordionChange('addOns')} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="addOns-content" id="addOns-header" className={`${classes.accordionSummary} ${expandedSection === 'addOns' ? classes.boldHeader : ''}`}>
          <Typography className={classes.accordianHeaders}  variant={headerVariant}>Add Ons</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <List style={{fontSize: bodyVariant, paddingLeft: 20}}>
                <ListItem >
                    {'\u2022'} Custom logo and branding on cups, napkins etc.
                </ListItem>
                <ListItem>
                    {'\u2022'} Additional speciality latte flavors
                </ListItem>
                <ListItem>
                    {'\u2022'} Longer serve time
                </ListItem>
                <ListItem>
                    {'\u2022'} Custom Drink Flavor
                </ListItem>
                <ListItem>
                    {'\u2022'} Matcha Horchata
                </ListItem>
                <ListItem>
                    {'\u2022'} Matcha (contract catering)
                </ListItem>
                <ListItem>
                    {'\u2022'} Chai (contract catering)
                </ListItem>
                <ListItem>
                    {'\u2022'} We're always open to new ideas! If you have a concept you're curious 
                    about, send us an email and we can make it a reality!
                </ListItem>
            </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default VerticalAccordion;
