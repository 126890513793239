import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import useStyles from '../MobileStyles';

const MobileContactPage = () => {
    const classes = useStyles()    

    const [eventType, setEventType] = React.useState('');

    const handleEventTypeChange = (event) => {
        setEventType(event.target.value);
    };
    
    return (
        <div className={classes.MobileContactPage}>
        <Typography variant="h6" gutterBottom>
            Contact Information
        </Typography>
        <form action="https://formspree.io/f/xnqeqybq" method="POST">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        autoComplete="name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email Address"
                        fullWidth
                        autoComplete="email"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        fullWidth
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="dateOfEvent"
                        name="dateOfEvent"
                        label="Date of Event"
                        fullWidth
                        type="date"
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="timeOfService"
                        name="timeOfService"
                        label="Time of Service Needed"
                        fullWidth
                        type="time"
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lengthOfService"
                        name="lengthOfService"
                        label="Length of Service Needed (in hours)"
                        fullWidth
                        type="text"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="numGuests"
                        name="numGuests"
                        label="Number of Guests"
                        fullWidth
                        type="number"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="budget"
                        name="budget"
                        label="Budget"
                        fullWidth
                        type="text"
                        variant="standard"
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        id="eventType"
                        name="eventType"
                        label="Type of Event"
                        fullWidth
                        type="text"
                        variant="standard"
                    />
                </Grid> */}
                    <Grid item xs={12} sm={12} style={{justifyContent: 'bottom', display: 'flex'}}>
                        <FormControl fullWidth required>
                        <InputLabel variant="standard" id="demo-simple-select-label"> Event Type </InputLabel>
                            <Select
                                required
                                labelId="demo-simple-select-label"
                                id="eventType"
                                name="eventType"
                                value={eventType}
                                onChange={handleEventTypeChange}
                                label="Event Type"
                                variant="standard"
                            >
                                <MenuItem value="Bridal Shower"> Bridal Shower </MenuItem>
                                <MenuItem value="Engagement">Engagement</MenuItem>
                                <MenuItem value="Wedding">Wedding</MenuItem>
                                <MenuItem value="Community Event">Community Event</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <TextField
                                required = {eventType == "Other" ? true : false}
                                id="Event_Clarification"
                                name="Event_Clarification"
                                label="Event Details (if Needed)"
                                fullWidth
                                type="text"
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        id="location"
                        name="location"
                        label="Location (Venue or Address)"
                        fullWidth
                        type="text"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="comments"
                        name="comments"
                        label="Comments, Questions, or Concerns"
                        fullWidth
                        rows={4}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                        <TextField
                            id="howTheyHeard"
                            name="howTheyHeard"
                            label="We'd love to know how you heard about us"
                            fullWidth
                            rows={3}
                            multiline='true'
                            // variant="standard"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <div className={classes.FormSubmitButtonContainer}>
                <Button type="submit" variant="contained"
                    sx={{
                        backgroundColor: '#F9E4BC',
                        color: 'black',
                        '&:hover': {
                            backgroundColor: '#F9E4BC',
                            boxShadow: 'none',
                        },
                        boxShadow: 'none',
                    }}
                >
                    Send Quote Request
                </Button>
            </div>
        </form>
    </div>
    )
}

export default MobileContactPage