import { makeStyles } from '@material-ui/core/styles'

// hook called useStyles
const useStyles = makeStyles((theme) => ({ // the theme object is part of MUI
    footer: {
        background: 'white',
        color: 'black',
        padding: '50px',
        marginTop: 50,
        fontFamily: 'garamond'
    },
    footerText: {
        color: 'inherit',
        fontFamily: 'inherit',
    },
    Navbar: {
        display: 'flex',
        backgroundColor: 'white',
        color: 'black', // #F9E4BC <-- cream color?
        borderBottom: '2px solid black',
    },
    NavbarContainer: {
        width: '100%', 
    },
    logoContainer: {
        justifyContent:'left',
        display: 'flex',
        alignItems: 'left',
    },
    logoImage: {
        maxWidth: "100%",
        height: 'auto',
        cursor: 'pointer',
    },
    navbarSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%', 
        width: '100%'
    },
    NavbarButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    TextButton: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    accordion: {
        boxShadow: 'none', // Remove box shadow
        border: 'none',    // Remove border
        '&:before': {
          display: 'none',  // Hide the accordion summary border
        },
      },
      accordionSummary: {
        '& .MuiAccordionSummary-expandIcon': {
          display: 'none',  // Hide the expand icon
        },
      },
    accordianHeaders: {
        fontFamily: 'garamond',
        color: 'inherit'
    },
    boldHeader: {
        fontWeight: 'bold',
        textShadow: '1px 0px 0px rgba(0, 0, 0, 1)'
    },
      
    

}));

export default useStyles;