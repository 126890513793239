import React from 'react'

import coffeeGif from '../images/Videos/coffee_gif.gif'

const LoadingPage = () => {
    return (
        <div style={{width: '100vw', height: '90vh', justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: '#fffcf1'}}>
            <img src={coffeeGif} alt="loading"/>
        </div>
    )
}

export default LoadingPage