import { Grid, Typography } from '@material-ui/core'
import React, {useEffect, useState} from 'react'

import useStyles from './pagesStyles'
import MobileMissionPage from '../Mobile/MobilePages/MobileMissionPage'
import SmallMissionPage from '../Pages/SmallWindowPages/SmallMissionPage'

import KelseyAndMacaylaPhoto from '../images/OfficialPhotos/OfficialPhoto3.png'
import KelseyPhoto from '../images/OfficialPhotos/OfficialPhoto6.png'
import MacaylaPhoto from '../images/OfficialPhotos/OfficialPhoto2.png'

const MissionPage = () => {
  const classes = useStyles()

  const [isTooSmall, setIsTooSmall] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
        setIsTooSmall(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  return (
    <>
    {isTooSmall ? (
      <SmallMissionPage KelseyAndMacaylaPhoto={KelseyAndMacaylaPhoto} KelseyPhoto={KelseyPhoto} MacaylaPhoto={MacaylaPhoto}/>
    ) : (
      <div style={{justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 40}} className={classes.LandingPage}>
        <Grid container spacing={4} style={{maxWidth: '100%'}}>
          <Grid item xs={12} >
            <Typography variant='h2' style={{fontFamily: 'garamond', textAlign: 'center'}}>
              About Us
            </Typography>
            <Grid item xs={12} style={{marginTop: 40}}>
              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <img className={classes.Image} src={KelseyAndMacaylaPhoto} alt="foundersPhoto"/>
                </Grid>
                <Grid item xs={7} className={classes.MissionPageText} >
                  <Typography variant='h5' style={{fontFamily: 'garamond', textAlign: 'center', marginRight: '7vw'}}>
                      Miele started in 2023 by Kelsey and Macayla who became friends during their time at 
                      Baylor. The two would always hang out at coffee shops and dream of one day having a 
                      coffee business of their own. After graduating, getting married to their husbands, 
                      and moving to the DFW area, they decided to make their dreams a 
                      reality and Miele was born! 
                  </Typography>
                  <Typography variant='h5' style={{fontFamily: 'garamond', textAlign: 'center', paddingTop: 60, marginRight: '7vw'}}>
                      miele: noun. /MEE-el/honye <br/>
                      Éntre miele: to be all sweetness and light 
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{marginTop: 40}}>
              <Grid container spacing={4}>
                <Grid item xs={7} className={classes.MissionPageText}>
                  <Typography variant='h5' style={{fontFamily: 'garamond', textAlign: 'center', marginLeft: '7vw'}}>
                    Kelsey fell in love with coffee when she had her first frappuccino in fifth grade. 
                    Through college, her entrepreneurial studies merged with a passion for nutrition. 
                    She channeled her dedication to healthy eating into the way she made her coffee,
                    using only wholesome ingredients. Her desire to start a business, along with her 
                    love for espresso, and her mission to avoid processed foods ignited the creation 
                    of this company dedicated to only using all natural ingredients.

                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <img className={classes.Image} src={KelseyPhoto} alt="foundersPhoto"/>
                </Grid>
              </Grid>
              </Grid>
            
          </Grid>
          <Grid item xs={12} style={{marginTop: 40}}>
              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <img className={classes.Image} src={MacaylaPhoto} alt="foundersPhoto"/>
                </Grid>
                <Grid item xs={7} className={classes.MissionPageText}>
                  <Typography variant='h5' style={{fontFamily: 'garamond', textAlign: 'center', marginRight: '7vw'}}>
                    Macayla has loved coffee for as long as she can remember. Her passion grew in 
                    undergrad where she spent hours at coffee shops studying and spending time with 
                    friends. Macayla has always had the dream of opening up a coffee shop and is now 
                    making the dream a reality with Miele!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
      </div>
    )}
    
    
    
    </>
  )
}

export default MissionPage