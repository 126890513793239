import React, { useState} from 'react'

import { Drawer, List, ListItem, ListItemText, IconButton } from '@material-ui/core'
import MenuIcon from '@mui/icons-material/Menu'
import {Link} from 'react-router-dom'

const HamburgerMenu = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    
    const menuItems = [
        { text: "Home", link: '/'},
        { text: 'Pricing and Packaging', link: '/Pricing' },
        { text: 'Get a Quote', link: '/Contact' },
        { text: 'About Us', link: '/About' },
    ];

    const handleLinkClick = () => {
        setDrawerOpen(false)
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <>
            <IconButton onClick={toggleDrawer(true)} color="inherit">
                <MenuIcon /> {/* Render the MenuIcon here */}
            </IconButton>
            
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <List>
                {menuItems.map((item) => (
                    <ListItem button key={item.text} component={Link} to={item.link} onClick={() => {handleLinkClick()}}>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
                </List>
            </Drawer>
        </>
    )
}

export default HamburgerMenu