import React, { useState, useEffect} from 'react'
import Navbar from '../Components/Navbar';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './pagesStyles';

// import stock1 from '../images/OfficialPhotos/OfficialPhoto4.png'
// import stock2 from '../images/OfficialPhotos/OfficialPhoto8.png'
import stock1 from '../images/OfficialPhotos/CoverPhoto1.png'
import stock2 from '../images/OfficialPhotos/CoverPhoto2.png'


import QuoteCarousel from '../Components/QuoteCarousel';

import SmallLandingPage from './SmallWindowPages/SmallLandingPage';
import LoadingPage from './LoadingPage';


const LandingPage = () => {
    const [loading, setLoading] = useState(false)
    // const [stock1, setStock1] = useState(null)
    // const [stock2, setStock2] = useState(null)

    const classes = useStyles();

      const [isTooSmall, setIsTooSmall] = useState(window.innerWidth < 1000);
    

      useEffect(() => {
    //     const loadImages = async () => {
    //         try {
    //             const importedImage1 = await import('../images/OfficialPhotos/OfficialPhoto4.png')
    //             const importedImage2 = await import('../images/OfficialPhotos/OfficialPhoto8.png')

    //             // setStock1(importedImage1.default)
    //             // setStock2(importedImage2.default)
    //             await new Promise(resolve => setTimeout(resolve, 1500));
                
    //             setLoading(false)
    //         } catch (error) {
    //             console.error("error loading images: ", error)
    //             setLoading(false)
    //         }
    //     }

        // loadImages()

        const handleResize = () => {
            setIsTooSmall(window.innerWidth <= 1100);
        };

        handleResize()

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (loading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [loading])

    if (loading) { return ( <LoadingPage/> ) } 
    if (isTooSmall) { return ( <SmallLandingPage stock1={stock1} stock2={stock2}/> ) }

    return (
        <div className={classes.LandingPage}>
            <Grid container className={classes.ImageAndTextContainer} style={{marginTop: 50}}>
                <Grid item xs={8} md={6} className={classes.ImageContainer}>
                    <img className={classes.Image} src={stock1} alt="Coffee"/>
                </Grid>
                <Grid item xs={4} md={6} className={classes.TextBody}>
                    <Typography variant='h2' style={{fontFamily: 'garamond', paddingBottom: 40, paddingRight: '5vw'}}>
                        Welcome to Café De Miele
                    </Typography>
                    <Typography variant='h5' style={{fontFamily: "garamond", paddingRight: '5vw'}}>
                        Welcome to Miele Coffee, where passion brews perfection in every cup. 
                        Renowned for our handcrafted espresso drinks made with natural, wholesome 
                        ingredients, we bring flavorful joy to the Dallas-Fort Worth Metroplex. 
                        From weddings to corporate events, bridal showers to birthdays, our mobile 
                        coffee service delivers exceptional taste and warm hospitality wherever we go.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.ImageAndTextContainer} style={{marginTop: 50}}>
                <Grid item xs={4} md={6} className={classes.TextBody}>
                    <Typography variant='h2' style={{fontFamily: 'garamond', paddingBottom: 40, paddingLeft: '5vw'}}>
                        Now Booking Events Starting February 2024
                    </Typography>
                    <Typography variant='h5' style={{fontFamily: "garamond", paddingLeft: '5vw'}}>
                        At Miele Coffee, serving goes beyond coffee; it's about crafting 
                        unforgettable moments. With a dedication to excellence and a commitment to 
                        delighting taste buds, we invite you to savor the art of our handcrafted brews 
                        and experience the essence of genuine care in every sip.
                    </Typography>
                </Grid>
                <Grid item xs={8} md={6} className={classes.ImageContainer}>
                    <img className={classes.Image} src={stock2} alt="Coffee"/>
                </Grid>
            </Grid>
        </div>

    )
}

export default LandingPage