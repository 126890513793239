import React from 'react'
import { Container, Grid, Button, IconButton } from '@mui/material'

import useStyles from '../MobileStyles'

import InstagramIcon from '@mui/icons-material/Instagram'
import EmailIcon from '@mui/icons-material/MailOutline';
import logo from '../../images/CafeDeMiele_Logo.png'
import HamburgerMenu from './HamburgerMenu';
import { useNavigate } from 'react-router-dom';

const MobileNavbar = () => {
    const classes = useStyles()
    const navigate = useNavigate();

    const handleLinkClick = (href) => {
        // window.location.pathname = href;
        navigate(href)
    }

    return (
        <div className={classes.Navbar}>
                <Container style={{minWidth: '100vw'}}>
                    <Grid container className={classes.NavbarContainer} spacing={4}>
                        <Grid item className={classes.logoContainer} xs={5} >
                            <img onClick={() => handleLinkClick("/")} className={classes.logoImage} src={logo}/>
                        </Grid>
                        <Grid item  xs={5} className={classes.navbarSection}>
                            <IconButton
                                href="https://www.instagram.com/miele.coffee/?igsh=eWZ6eHpyYWt2bmZ0&utm_source=qr"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="inherit"
                                >
                                    <InstagramIcon fontSize="large" />
                                </IconButton>
                                <IconButton
                                component="a"
                                href="mailto:Cafedemiele@gmail.com"
                                color="inherit"
                                >
                                    <EmailIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2} className={classes.MenuContainer}>
                            <HamburgerMenu/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
    )
}

export default MobileNavbar