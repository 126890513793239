import React, { useState } from 'react';
import { Button, Container, Grid, IconButton, Collapse, TextField } from '@material-ui/core';
import useStyles from './componentStyles';
import {Link, useNavigate} from 'react-router-dom'


import InstagramIcon from '@mui/icons-material/Instagram'
import EmailIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from '../images/CafeDeMiele_Logo.png'

const Navbar = () => {
    const classes = useStyles();
    const [isEmailFormOpen, setIsEmailFormOpen] = useState(false);
    const [emailContent, setEmailContent] = useState('');
    const navigate = useNavigate();

    // const navigate = useNavigate()

    const handleLinkClick = (href) => {
        // <Link to={href}/>
        
        navigate('/')

        // window.location.pathname = href;
        // navigate(href)
    }

    return (
        <div className={classes.Navbar}>
            <Container style={{minWidth: '100vw'}}>
                    <Grid container className={classes.NavbarContainer} spacing={4}>
                        <Grid item className={classes.logoContainer} xs={3} lg={2}>
                            <img onClick={() => handleLinkClick()} className={classes.logoImage} src={logo}/>
                        </Grid>
                        <Grid className={classes.navbarSection} item xs={7} lg={8}>
                            <Grid container>
                                <Grid item xs={12} md={4} className={classes.NavbarButtons}>
                                    <Button variant='text' disableRipple 
                                    className={classes.TextButton} component={Link} to="/Pricing" replace>
                                        Pricing and Packages
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4} className={classes.NavbarButtons}>
                                    <Button variant='text' disableRipple 
                                    className={classes.TextButton} component={Link} to="/About" replace>
                                        About Us
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={4} className={classes.NavbarButtons} >
                                    <Button variant='text' disableRipple 
                                    className={classes.TextButton} component={Link} to="/Contact" replace>
                                        Get a Quote
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.navbarSection} xs={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <IconButton
                                    href="https://www.instagram.com/miele.coffee/?igsh=eWZ6eHpyYWt2bmZ0&utm_source=qr"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    color="inherit"
                                    >
                                        <InstagramIcon fontSize="large" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                <IconButton
                                    component="a"
                                    href="mailto:Cafedemiele@gmail.com"
                                    color="inherit"
                                    >
                                        <EmailIcon fontSize="large" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
        </div>
    )
}

export default Navbar