import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Import BrowserRouter and Route
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HashRouter, Routes, Route } from 'react-router-dom'

// Computer Imports
import MissionPage from './Pages/MissionPage';
import ContactPage from './Pages/ContactPage';
import PricingPage from './Pages/PricingPage';
import LandingPage from './Pages/LandingPage';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

// Mobile Imports
import MobileNavbar from './Mobile/MobileComponents/MobileNavbar';
import MobileLandingPage from './Mobile/MobilePages/MobileLandingPage';
import MobileContactPage from './Mobile/MobilePages/MobileContactPage';
import MobileMissionPage from './Mobile/MobilePages/MobileMissionPage';
import MobilePricingPage from './Mobile/MobilePages/MobilePricingPage';

export default function App() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div>
      {isMobile ? (
        // <BrowserRouter basename="/cafedemiele">
        <HashRouter>
          <MobileNavbar />
          <Routes>
            <Route path="/" element={<MobileLandingPage />} />
            <Route path="/contact" element={<MobileContactPage />} />
            <Route path="/about" element={<MobileMissionPage />} />
            <Route path="/pricing" element={<MobilePricingPage />} />
          </Routes>
          <Footer />
        {/* </BrowserRouter> */}
        </HashRouter>
      ) : (
        // <BrowserRouter basename="/cafedemiele">
        <HashRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<MissionPage />} />
            <Route path="/pricing" element={<PricingPage />} />
          </Routes>
          <Footer />
        {/* </BrowserRouter> */}
        </HashRouter>
      )}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
