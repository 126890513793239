import React from 'react'
import { Typography, Grid } from '@material-ui/core'

import useStyles from '../MobileStyles'
// import KelseyAndMacaylaPhoto from '../../images/KelseyAndMacaylaPhoto.png'
// import KelseyPhoto from '../../images/KelseyPhoto.png'
// import MacaylaPhoto from '../../images/MacaylaPhoto.jpeg'

import KelseyAndMacaylaPhoto from '../../images/OfficialPhotos/OfficialPhoto3.png'
import KelseyPhoto from '../../images/OfficialPhotos/OfficialPhoto6.png'
import MacaylaPhoto from '../../images/OfficialPhotos/OfficialPhoto2.png'

const MobileMissionPage = () => {
    const classes = useStyles();

    return (
        <div style={{justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', marginLeft: '7vw', marginRight: '7vw', marginTop: 40}}>
            <Grid container spacing={4} >
                <Grid item xs={12} className={classes.LandingHeaderText}>
                    <Typography variant='h5' style={{fontFamily: 'garamond'}}>
                        About Us
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={KelseyAndMacaylaPhoto} style={{objectFit: 'cover'}} alt="Macayla and Kelsey"/>
                </Grid>
                <Grid item xs={12} style={{margin: '7vw'}}>
                    <Typography className={classes.LandingPageText}>
                        Miele started in 2023 by Kelsey and Macayla who became friends during their time at 
                        Baylor. The two would always hang out at coffee shops and dream of one day having a 
                        coffee business of their own. After graduating, getting married to their husbands, 
                        and moving to the DFW area, they decided to make their dreams a 
                        reality and Miele was born! 
                    </Typography>
                    <Typography variant='h6' style={{fontFamily: 'garamond', textAlign: 'center', paddingTop: 60}}>
                        miele: noun. /MEE-el/honye <br/>
                        Éntre miele: to be all sweetness and light 
                    </Typography>

                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={KelseyPhoto} style={{objectFit: 'cover'}} alt="Kelsey"/>
                </Grid>
                <Grid item xs={12} style={{margin: '7vw'}}>
                    <Typography className={classes.LandingPageText}>
                        Kelsey fell in love with coffee when she had her first frappuccino in fifth grade. 
                        Through college, her entrepreneurial studies merged with a passion for nutrition. 
                        She channeled her dedication to healthy eating into the way she made her coffee,
                        using only wholesome ingredients. Her desire to start a business, along with her 
                        love for espresso, and her mission to avoid processed foods ignited the creation 
                        of this company dedicated to only using all natural ingredients.
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{maxHeight: '50vh', display: 'flex'}}>
                    <img className={classes.logoImage} src={MacaylaPhoto} style={{objectFit: 'cover'}} alt="Macayla"/>
                </Grid>
                <Grid item xs={12} style={{margin: '7vw'}}>
                    <Typography className={classes.LandingPageText}>
                        Macayla has loved coffee for as long as she can remember. Her passion grew in 
                        undergrad where she spent hours at coffee shops studying and spending time with 
                        friends. Macayla has always had the dream of opening up a coffee shop and is now 
                        making the dream a reality with Miele!
                    </Typography>
                </Grid>

            </Grid>
        </div>
    )
}

export default MobileMissionPage