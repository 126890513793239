import React from 'react'
import { Typography } from '@material-ui/core'

import useStyles from './componentStyles'
import logo from '../images/CafeDeMiele_Logo.png'

const Footer = () => {
    const classes = useStyles()
    return (
        <footer className={classes.footer}>
            <Typography variant='h4' align='center' gutterBottom style={{fontFamily: 'inherit'}}>
                Café De Miele
            </Typography>
            <Typography variant='subtitle1' align='center' className={classes.footerText}>
                Macayla Minton and Kelsey Stallworth
            </Typography>
            <Typography variant='subtitle1' align='center' className={classes.footerText}>
                (785) 691-9011
            </Typography>
            <Typography variant='subtitle1' align='center' className={classes.footerText}>
                CafeDeMiele@gmail.com
            </Typography>
        </footer>
    )
}

export default Footer